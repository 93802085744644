import React from "react"
import Layout from "../../../templates/layout"
import SEO from "../../../components/seo/seo"
import Hero from "../../../components/hero/hero"
import HaveQuestions from "../../../components/haveQuestions/haveQuestions"
import BrandLogo from "../../../images/logos/sarclisa-logo-new.svg";
import QuestionsIcon from "../../../images/icons/icon-questions-sarclisa.svg"
import HeroIcon from "../../../images/icons/icon-enroll-sarclisa-hero.svg"
import BackToTop from "../../../components/backToTop/BackToTop"
import EnrollCard from "../../../components/enrollCard/enrollCard"
import PrescribingInfo from "../../../components/prescribingInfo/prescribingInfo"

import Monitor from "../../../images/icons/sarclisa/sarclisa-monitor-icon.svg"
import Printer from "../../../images/icons/sarclisa/sarclisa-printer-icon.svg"

const pageContent = () => (
  <Layout pageid="page-enrollment">
    <SEO
      title="CareASSIST enrollment for SARCLISA® (isatuximab-irfc) | HCP Site"
      keywords="CareASSIST, Sanofi, SARCLISA® (isatuximab-irfc), HCP Site, Enrollment"
      description="Ready to help a patient enroll? Enroll online. See full Prescribing Info"
    />

    <Hero
      brandLogo={BrandLogo}
      headline="Enrollment"
      copy="Care Managers can help your office and patients with the enrollment process"
      heroImgClass="hero-icon"
      hasButton={false}
      hasLogo={true}
      hasIcon={true}
      heroIcon={HeroIcon}
      iconAlt="Enrollment Icon"
      brandAlt="Sarclisa logo"
      btnClass="blue"
    />

    <section className="content-section">
    <main>
        <div className="main-content m-b-80">

          <h2 className="m-b-60 m-x-30 sm-m-x-0 fs-26">
            2 ways for your patients to get started with CareASSIST 
          </h2>

          <EnrollCard 
            alt="Online enrollment icon"
            cardImage={Monitor} 
            cardClass="sarclisa vertical-center"
            header="Online"
            enrollLinkText="Enroll online"
            btnClass="internal-btn blue"
            content={<><strong>Select</strong> the button below and complete all requested fields. This will provide an electronic consent option for patients who are not in  your office at the time of enrollment.</>}
            btnLink="https://sanoficareassisthcp.iassist.com/"
            linkId="hcp-sarclisa-enrollment-enroll-online"
          />

          <h2 className="text-center m-y-40">OR</h2>


          <EnrollCard 
            alt="Fax enrollment icon"
            cardImage={Printer} 
            cardClass="sarclisa download-card"
            header="Fax"
            downloadLinkText="Enrollment form"
            spanshDownloadLinkText="Spanish enrollment form"
            btnClass="internal-btn blue"
            content={<><strong>Download</strong> and print the form, then fax it to us at:</>}
            fax="1-855-411-9689"
            btnLink="../../../pdfs/careassist-program-enrollment-form.pdf"
            spanishBtnLink="../../../pdfs/careassist-spanish-enrollment-form.pdf"
            linkId="hcp-sarclisa-enrollment-enroll-form"
            secondLinkId="hcp-sarclisa-enrollment-spanish-enroll-form"
          />

         
        </div>
      </main>
      <PrescribingInfo linkId="hcp-sarclisa-enrollment-prescribing-information" link="https://products.sanofi.us/Sarclisa/sarclisa.pdf" hideBoxed="hide-boxed" />
      <HaveQuestions icon={QuestionsIcon} />
    </section>
    <div className="back-to-top-container">
        <BackToTop />
      </div>
  </Layout>
);

export default pageContent